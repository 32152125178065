import React from 'react'

export default function Payment() {
  return (
    <>
    <div class="page-title" data-aos="fade">
      <nav class="breadcrumbs">
        <div class="container">
          <ol>
            <li><a href="index.html">Home</a></li>
            <li class="current">Payment</li>
          </ol>
        </div>
      </nav>
      <div class="heading">
        <div class="container">
          <div class="row d-flex justify-content-center text-center">
            <div class="col-lg-8">
              <h1>Payment</h1>
              <p class="mb-0"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="pricing" class="pricing section">

    <div class="container">

      <div class="row gy-3">

          <h5><strong>Payment Modes:</strong></h5>
            <ol>
                 <li>Branch/ATM Cheque drop</li>
                 <li>Cheque or Cash</li>
                 <li>Goole pay/Phone pe</li>
                 <li>NEFT/IMPS</li>
            </ol>
      </div>
    </div>
    </section>
    </>
  )
}
